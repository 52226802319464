import { AccredibleSocialMedia } from '@accredible-frontend-v2/models';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';

@Component({
  selector: 'sl-social-links',
  templateUrl: 'social-links.component.html',
  styleUrls: ['social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLinksComponent implements OnInit {
  private readonly _languageService = inject(AccredibleLanguageService);
  name = input<string>();
  socialLinks = input<AccredibleSocialMedia>();
  ariaLabel = input<string>();
  showAdditionalLinks = input<boolean>(false);
  socialNetworkClicked = output<string>();

  twitterLink: string;
  additionalSocialLinksChips = computed(() => {
    if (!this.showAdditionalLinks() || !this.socialLinks()) {
      return [];
    }

    const excludedSocialTypes = ['linkedin', 'twitter'];
    const socialMediaList = [];

    for (const [provider, value] of Object.entries(this.socialLinks())) {
      if (excludedSocialTypes.includes(provider)) {
        continue;
      }

      if (provider === 'other') {
        socialMediaList.push({
          value: value.name || this._languageService.translate('social-links.other'),
          url: value.url,
        });
      } else {
        socialMediaList.push({
          value: this._languageService.translate(`social-links.${provider}`),
          url: value,
        });
      }
    }

    return socialMediaList;
  });

  ngOnInit(): void {
    if (this.socialLinks()?.twitter) {
      // Twitter usernames won't contain any symbols or special characters, so it's safe to remove the @ symbol
      // For reference: https://help.twitter.com/en/managing-your-account/twitter-username-rules
      const twitterUsername = <string>this.socialLinks()?.twitter;
      this.twitterLink =
        'https://twitter.com/' +
        twitterUsername
          // Removing the @ symbol if exists
          .replace(/^@/, '')
          .replace(/^(https?:\/\/)?(www\.)?twitter\.com\//, '');
    }
  }
}
