@for (credential of credentials(); track credential.url) {
  <div class="timeline-item"
    [class.ghost]="isLoading()">

    <div class="date standard trailing-slight-wide">
      <div>{{ credential.issue_date | localeDate:'mediumDate': (languageCode$ | async) }}</div>
    </div>

    <div class="blob">
      <mat-icon class="trailing-margin">
        radio_button_checked
      </mat-icon>

      <div class="date mobile trailing-slight-wide">
        <div>{{ credential.issue_date | localeDate:'mediumDate': (languageCode$ | async) }}</div>
      </div>
      <div class="line"></div>
    </div>

    <sl-certification [credential]="credential"/>
  </div>
}
