import { AccredibleChipComponentModule } from '@accredible-frontend-v2/new-components/chip';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { SocialLinksComponent } from './social-links.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslocoModule,
    AccredibleIconComponentModule,
    AccredibleChipComponentModule,
  ],
  exports: [SocialLinksComponent],
  declarations: [SocialLinksComponent],
  providers: [],
})
export class SocialLinksComponentModule {}
