import { DashboardCorporation } from '@accredible-frontend-v2/models';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter } from 'rxjs';
import { EmbedMessage, EmbedMessageNavigate, EmbedMessagePayload } from '../embedding.model';

const CLIENT_API_KEY_SECRET = 'client_api_key_secret';

const V2_V1_ROUTES_MAP = new Map([
  // User
  ['/user/sign-in', 'issuer.login'],
  ['/user/sign-up', 'issuer.login.sign-up'],
  ['/user/forgot-password', 'issuer.login.forgot-password'],
  ['/user/reset-password', 'issuer.login.reset-password'],
  ['/user/locked-account', 'issuer.login.locked-account'],
  ['/user/verify-email-error', 'verify-email-error'],
  ['/user/mfa-setup', 'issuer.mfa.mfa-setup'],
  ['/user/mfa-configure', 'issuer.mfa.mfa-configure'],
  ['/user/mfa-sign-in', 'issuer.login.mfa-sign-in'],
  ['/user/mfa-lost-device', 'issuer.login.mfa-lost-device'],
  ['/verification', 'issuer.dashboard.verification'],
  // Department
  ['/department/general-settings', 'issuer.dashboard.department.general-settings'],
  ['/department/branding-settings', 'issuer.dashboard.department.branding-settings'],
  ['/department/recipients-experience', 'issuer.dashboard.department.recipients-experience'],
  ['/department/recipient-support', 'issuer.dashboard.department.recipient-support'],
  ['/department/market-my-courses', 'issuer.dashboard.department.market-my-courses'],
  ['/department/security', 'issuer.dashboard.department.security'],
  ['/department/api-settings', 'issuer.dashboard.department.api-settings'],
  ['/department/api-settings/:id', 'issuer.dashboard.department.api-settings.api-key'],
  ['/department/api-settings/create', 'issuer.dashboard.department.api-settings.create'],
  // Team members
  ['/team-members', 'issuer.dashboard.team-members'],
  ['/team-members/add', 'issuer.dashboard.team-members.add'],
  ['/team-members/:id', 'issuer.dashboard.team-members.edit'],
  // Directories
  ['/directories', 'issuer.dashboard.directories'],
  ['/directories/:id', 'issuer.dashboard.directories.directory'],
  // Groups
  ['/groups', 'issuer.dashboard.groups'],
  // Collections
  ['/collections', 'issuer.dashboard.collections.list'],
  // Credentials
  ['/credentials', 'issuer.dashboard.credentials'],
  ['/credentials/:id', 'issuer.dashboard.credentials.view'],
  ['/credentials/name-change-requests', 'issuer.dashboard.credentials.name-change-request'],
  // Create credentials via form
  [
    '/credentials/create-via-form/fill',
    'issuer.dashboard.credentials.create-credentials-form-v2-fill',
  ],
  [
    '/credentials/create-via-form/summary',
    'issuer.dashboard.credentials.create-credentials-form-v2-summary',
  ],
  // Create credentials single group
  [
    '/credentials/create/single-group/upload',
    'issuer.dashboard.credentials.create-single-group-upload',
  ],
  [
    '/credentials/create/single-group/assign',
    'issuer.dashboard.credentials.create-single-group-assign',
  ],
  [
    '/credentials/create/single-group/validate',
    'issuer.dashboard.credentials.create-single-group-validate',
  ],
  [
    '/credentials/create/single-group/create',
    'issuer.dashboard.credentials.create-single-group-create',
  ],
  [
    '/credentials/create/single-group/summary',
    'issuer.dashboard.credentials.create-single-group-summary',
  ],
  // Update credentials single group
  [
    '/credentials/update/single-group/upload',
    'issuer.dashboard.credentials.update-single-group-upload',
  ],
  [
    '/credentials/update/single-group/assign',
    'issuer.dashboard.credentials.update-single-group-assign',
  ],
  [
    '/credentials/update/single-group/validate',
    'issuer.dashboard.credentials.update-single-group-validate',
  ],
  [
    '/credentials/update/single-group/create',
    'issuer.dashboard.credentials.update-single-group-create',
  ],
  [
    '/credentials/update/single-group/summary',
    'issuer.dashboard.credentials.update-single-group-summary',
  ],
  // Create credentials multiple groups
  [
    '/credentials/create/multiple-groups/upload',
    'issuer.dashboard.credentials.create-multiple-groups-upload',
  ],
  [
    '/credentials/create/multiple-groups/assign',
    'issuer.dashboard.credentials.create-multiple-groups-assign',
  ],
  [
    '/credentials/create/multiple-groups/validate',
    'issuer.dashboard.credentials.create-multiple-groups-validate',
  ],
  [
    '/credentials/create/multiple-groups/create',
    'issuer.dashboard.credentials.create-multiple-groups-create',
  ],
  [
    '/credentials/create/multiple-groups/summary',
    'issuer.dashboard.credentials.create-multiple-groups-summary',
  ],
  // Update credentials multiple groups
  [
    '/credentials/update/multiple-groups/upload',
    'issuer.dashboard.credentials.update-multiple-groups-upload',
  ],
  [
    '/credentials/update/multiple-groups/assign',
    'issuer.dashboard.credentials.update-multiple-groups-assign',
  ],
  [
    '/credentials/update/multiple-groups/validate',
    'issuer.dashboard.credentials.update-multiple-groups-validate',
  ],
  [
    '/credentials/update/multiple-groups/create',
    'issuer.dashboard.credentials.update-multiple-groups-create',
  ],
  [
    '/credentials/update/multiple-groups/summary',
    'issuer.dashboard.credentials.update-multiple-groups-summary',
  ],
  // Insights
  ['/insights/overview', 'issuer.dashboard.analytics.overview'],
  ['/insights/recipient-activity', 'issuer.dashboard.analytics.recipient-activity'],
  ['/insights/recipient-activity/print', 'issuer.dashboard.analytics.recipient-activity-print'],
  ['/insights/pathways', 'issuer.dashboard.analytics.pathways'],
  ['/insights/email-campaigns', 'issuer.dashboard.analytics.email-campaigns'],
  ['/insights/engagement', 'issuer.dashboard.analytics.recipient-engagement'],
  ['/insights/engagement/print', 'issuer.dashboard.analytics.recipient-engagement-print'],
  ['/insights/spotlight-directories', 'issuer.dashboard.analytics.spotlight-directories'],
  ['/insights/recommendations', 'issuer.dashboard.analytics.recommendations'],
  ['/insights/marketing', 'issuer.dashboard.analytics.market-reach'],
  ['/insights/marketing-print', 'issuer.dashboard.analytics.market-reach-print'],
  ['/insights/marketing/influencers', 'issuer.dashboard.analytics.influencers'],
  ['/insights/marketing/referral-calculator', 'issuer.dashboard.analytics.referral-calculator'],
  ['/insights/departments', 'issuer.dashboard.analytics.departments-usage'],
  ['/insights/logs', 'issuer.dashboard.analytics.logs'],
  // Integrations
  ['/integrations', 'issuer.dashboard.integrations'],
  ['/integrations/details/:kind', 'issuer.dashboard.integrations.integration'],
  ['/integrations/zoom/credential-issuance', 'issuer.dashboard.zoom-credential-issuance'],
  [
    '/integrations/zoom/credential-issuance/:id',
    'issuer.dashboard.zoom-credential-issuance.manage',
  ],
  ['/integrations/blackboard/lti', 'issuer.blackboard-lti'],
  ['/integrations/canvas/lti/course-settings', 'issuer.canvas-lti'],
  ['/integrations/canvas/lti/admin-settings', 'issuer.canvas-lti-admin'],
  ['/integrations/canvas/lti/admin-settings/oauth-redirect', 'issuer.canvas-lti-oauth-redirect'],
  ['/integrations/integration/lti-launch-error', ''],
  [
    '/integrations/skilljar/account-configuration',
    'issuer.dashboard.integrations.skilljar.account-configuration',
  ],
  [
    '/integrations/skilljar/credential-issuance',
    'issuer.dashboard.integrations.skilljar.credential-issuance',
  ],
  [
    '/integrations/benchprep/credential-issuance/:id',
    'issuer.dashboard.integrations.benchprep-credential-issuance',
  ],
  // LTI 1.3
  ['/integrations/:kind/lti/tool-settings', 'issuer.lti-v1p3'],
  ['/integrations/:kind/lti/oauth2-callback', 'issuer.lti-v1p3-oauth2-callback'],
  // Pathways
  ['/pathways', 'issuer.dashboard.pathways'],
  ['/pathways/track-list', 'issuer.dashboard.pathways.track-list'],
  ['/pathways/create', 'issuer.dashboard.pathways.pathway'],
  ['/pathways/:id/version/:versionId', 'issuer.dashboard.pathways.pathway-edit'],
  [
    '/pathways/:id/version/:versionId/eligible-list',
    'issuer.dashboard.pathways.pathway-eligible-list',
  ],
  ['/pathways/create-pathway-track', 'issuer.dashboard.pathways.pathway-track'],
  ['/pathways/pathway-track/:id', 'issuer.dashboard.pathways.pathway-track-edit'],
  ['/pathways/pathway-track/:id/preview', 'issuer.dashboard.pathways.pathway-track-preview'],
  // Email Campaigns
  ['/email-campaigns', 'issuer.dashboard.email-settings.email-campaigns'],
  ['/email-campaigns/custom-campaigns', 'issuer.dashboard.email-settings.email-campaigns.custom'],
  [
    '/email-campaigns/essential-campaigns',
    'issuer.dashboard.email-settings.email-campaigns.essential',
  ],
  [
    '/email-campaigns/create',
    'issuer.dashboard.email-settings.email-campaigns.email-campaign-create',
  ],
  ['/email-campaigns/:id', 'issuer.dashboard.email-settings.email-campaigns.email-campaign-edit'],
  [
    '/email-campaigns/:id/details',
    'issuer.dashboard.email-settings.email-campaigns.email-campaign-details',
  ],
  // Find Campaigns
  ['/find-campaigns', 'issuer.dashboard.email-settings.find-campaigns'],
  // Email Templates
  ['/new-email-templates', 'issuer.dashboard.email-settings.new-email-templates'],
  [
    '/new-email-templates/custom-templates',
    'issuer.dashboard.email-settings.new-email-templates.custom',
  ],
  [
    '/new-email-templates/essential-templates',
    'issuer.dashboard.email-settings.new-email-templates.essential',
  ],
  [
    '/new-email-templates/create',
    'issuer.dashboard.email-settings.new-email-templates.email-template-create',
  ],
  [
    '/new-email-templates/:id',
    'issuer.dashboard.email-settings.new-email-templates.email-template-edit',
  ],
  [
    '/new-email-templates/essential-template/:id',
    'issuer.dashboard.email-settings.new-email-templates.essential-template',
  ],
  // Billing
  ['/purchase-plan', 'issuer.dashboard.billing.purchase-plan'],
  // Error
  ['/404', '404'],
  // My Account
  ['/my-account', 'issuer.dashboard.my_account'],
]);

interface ClientApiKeyAndSecret {
  apiKey: string;
  consumerKey: string;
  consumerSecret: string;
}

const PATHWAYS_EDIT_ROUTE_REGEXP = /\/pathways\/([0-9a-z-]+)\/version\/([0-9a-z-]+)/;
const PATHWAYS_ELIGIBLE_ROUTE_REGEXP =
  /\/pathways\/([0-9a-z-]+)\/version\/([0-9a-z-]+)\/eligible-list/;

const PATHWAYS_TRACKS_PREVIEW_REGEXP = /\/pathways\/pathway-track\/([0-9a-z-]+)\/preview/;

const INTEGRATION_DETAILS_ROUTE_REGEXP = /\/integrations\/details\/([0-9a-z-_]+)/;

const EMAIL_CAMPAIGN_DETAILS_ROUTE_REGEXP = /\/email-campaigns\/([0-9a-z-]+)\/details/;

const LTI_V1P3_ROUTE_REGEXP = /\/integrations\/lti_v1p3_([0-9a-z-_]+)\/lti/;

/**
 * This service is used to communicate with V1 Dashboard
 *
 * All logic related to this service should be deleted when we stop using iframes
 */

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class EmbeddingService {
  v1DataLoaded$ = new BehaviorSubject<boolean>(false);
  // Public variables used to always know which user is logged and if he is admin on V1
  issuerLoginId: number;
  isAdmin = false;
  apiPermission = false;
  // Public variables used to always know which department is selected on V1
  departmentId: number;
  // Public variable to know if the corporation is verified
  corporation: DashboardCorporation;
  origin: string;
  private _clientApiKeyAndSecret: ClientApiKeyAndSecret;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _browserStorage: AccredibleBrowserStorageService,
  ) {
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        const fullUrl = this._router.url.split('?');
        let url = fullUrl[0];
        const params = url.split('/');
        const route = V2_V1_ROUTES_MAP.get(url);
        const isLastParamAnIdOrUuid = this.isLastParamAnIdOrUuid(params);

        let id;
        let routeParams;

        // SPECIFIC CASES
        if (!route) {
          // Integrations :kind
          if (INTEGRATION_DETAILS_ROUTE_REGEXP.test(url)) {
            const [, kind] = url.match(INTEGRATION_DETAILS_ROUTE_REGEXP) || [];
            url = '/integrations/details/:kind';
            routeParams = {
              kind,
            };
          }

          // LTI 1.3 /integrations/:kind/lti
          if (LTI_V1P3_ROUTE_REGEXP.test(url)) {
            const [, kind] = url.match(LTI_V1P3_ROUTE_REGEXP) || [];
            url = '/integrations/:kind/lti';
            routeParams = {
              kind,
            };
          }

          // Email Campaigns /:id/details
          if (EMAIL_CAMPAIGN_DETAILS_ROUTE_REGEXP.test(url)) {
            const [, campaignId] = url.match(EMAIL_CAMPAIGN_DETAILS_ROUTE_REGEXP) || [];
            url = '/email-campaigns/:id/details';
            id = campaignId;
          }

          // Pathways /:id/version/:versionId
          if (PATHWAYS_TRACKS_PREVIEW_REGEXP.test(url)) {
            const [, pathwayUuid] = url.match(PATHWAYS_TRACKS_PREVIEW_REGEXP) || [];
            url = '/pathways/pathway-track/:id/preview';
            id = pathwayUuid;
          } else if (PATHWAYS_ELIGIBLE_ROUTE_REGEXP.test(url)) {
            const [, pathwayUuid, pathwayVersionUuid] =
              url.match(PATHWAYS_ELIGIBLE_ROUTE_REGEXP) || [];
            url = '/pathways/:id/version/:versionId/eligible-list';
            id = pathwayUuid;
            routeParams = {
              versionId: pathwayVersionUuid,
            };
          } else if (PATHWAYS_EDIT_ROUTE_REGEXP.test(url)) {
            const [, pathwayUuid, pathwayVersionUuid] = url.match(PATHWAYS_EDIT_ROUTE_REGEXP) || [];
            url = '/pathways/:id/version/:versionId';
            id = pathwayUuid;
            routeParams = {
              versionId: pathwayVersionUuid,
            };
          } else if (isLastParamAnIdOrUuid) {
            url = url.slice(0, url.lastIndexOf('/')) + '/:id';

            id = params[params.length - 1];
          }
        }

        // Makes sure to update V1 route
        this.postMessage<EmbedMessageNavigate>({
          type: 'navigate',
          payload: {
            route: <string>V2_V1_ROUTES_MAP.get(url),
            id,
            routeParams: {
              ...routeParams,
              ...Object.fromEntries(new URLSearchParams(fullUrl[1]).entries()),
            },
          },
        });
      });
  }

  setOrigin(origin: string): void {
    this.origin = origin; // This is required for communication back to the hosts domain

    this.addBodyClickEventListener();
  }

  setV1Data(
    issuerLoginId: number,
    isAdmin = false,
    apiPermission = false,
    departmentId: number,
    corporation: DashboardCorporation,
  ): void {
    this.issuerLoginId = issuerLoginId;
    this.isAdmin = isAdmin;
    this.apiPermission = apiPermission;
    this.departmentId = departmentId;
    this.corporation = corporation;
    this.v1DataLoaded$.next(true);
  }

  getClientApiKeyAndSecret(): ClientApiKeyAndSecret {
    const clientApiKeyAndSecret = this._browserStorage.get(CLIENT_API_KEY_SECRET, 'sessionStorage');
    if (clientApiKeyAndSecret) {
      this._clientApiKeyAndSecret = JSON.parse(clientApiKeyAndSecret);
    }

    return this._clientApiKeyAndSecret;
  }

  setClientApiKeyAndSecret(apiKey: string, consumerKey: string, consumerSecret: string): void {
    const cached = this.getClientApiKeyAndSecret();
    this._clientApiKeyAndSecret = {
      apiKey: apiKey ?? cached?.apiKey,
      consumerKey: consumerKey ?? cached?.consumerKey,
      consumerSecret: consumerSecret ?? cached?.consumerSecret,
    };
    // persist values in session storage to handle page refreshes
    this._browserStorage.set(
      CLIENT_API_KEY_SECRET,
      JSON.stringify(this._clientApiKeyAndSecret),
      'sessionStorage',
    );
  }

  postMessage<T = EmbedMessagePayload>(message: EmbedMessage<T>): void {
    parent.postMessage(message, this.origin);
  }

  sendTrackingEventToGA(gaEvent: string): void {
    this.postMessage({
      type: 'trackEvent',
      payload: { data: gaEvent },
    });
  }

  addBodyClickEventListener(): void {
    this._document.body.addEventListener('click', () => {
      this.postMessage({ type: 'bodyClick' });
    });
  }

  isLastParamAnIdOrUuid(params: string[]): boolean {
    const lastParam = params[params.length - 1];
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return !isNaN(+lastParam) || uuidRegex.test(lastParam);
  }
}
