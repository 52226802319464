<div class="tile no-hover"
  *transloco="let t; read: 'sl-certifications-list'"
  [class.ghost]="isLoading()">

  <div class="top-container">
    <div class="top-container-title">
      {{ t('credentials') }}
    </div>

    <a class="top-container-wallet"
      [href]="walletURL()"
      target="_blank"
      data-cy="link-wallet">
      {{ t('wallet') }}
      <accredible-icon icon="external"/>
    </a>
  </div>
  @if (profile().directory_credentials.length) {
    <div class="credentials-from">
      <div class="credentials-from-directory">
        <div class="from">
          {{ t('credentials_from') }}
          <span class="directory">
          {{ (directory$ | async)?.name }}
        </span>
        </div>

      </div>

      <sl-timeline [credentials]="profile().directory_credentials"
        [isLoading]="isLoading()"/>
    </div>
  }

  @if (profile().non_directory_credentials.length) {
    <div class="divider"></div>

    <div class="other-credentials">
      <div class="other-credentials-title">
        {{ t('other_credentials') }}
      </div>

      <sl-timeline [credentials]="profile().non_directory_credentials"
        [isLoading]="isLoading()"/>
    </div>
  }
</div>
