import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { forbiddenGuard } from '@spotlight/feature-forbidden';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { profilePageGuard } from '@spotlight/feature-profile';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { unauthorizedGuard } from '@spotlight/feature-unauthorized';

export enum SpotlightRoutes {
  PROFILE = 'profile',
  NEW_PROFILE = 'profile-page/:id',
  NOT_FOUND = '404',
  UNAUTHORIZED = 'unauthorized',
  FORBIDDEN = 'forbidden',
}

const routes: Routes = [
  // Can access regardless of authentication status
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('../containers/profile-list/profile-list.container.module').then(
        (m) => m.ProfileListContainerModule,
      ),
  },
  {
    canActivate: [profilePageGuard],
    path: SpotlightRoutes.PROFILE,
    loadChildren: () =>
      import('../containers/profile/profile.container.module').then(
        (m) => m.ProfileContainerModule,
      ),
  },
  {
    canActivate: [profilePageGuard],
    path: SpotlightRoutes.NEW_PROFILE,
    // eslint-disable-next-line @nx/enforce-module-boundaries
    loadComponent: () => import('@spotlight/feature-profile').then((m) => m.ProfileComponent),
  },
  {
    canActivate: [unauthorizedGuard],
    path: SpotlightRoutes.UNAUTHORIZED,
    loadComponent: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@spotlight/feature-unauthorized').then((m) => m.UnauthorizedComponent),
  },
  {
    canActivate: [forbiddenGuard],
    path: SpotlightRoutes.FORBIDDEN,
    // eslint-disable-next-line @nx/enforce-module-boundaries
    loadComponent: () => import('@spotlight/feature-forbidden').then((m) => m.ForbiddenComponent),
  },

  // Invalid Routes
  {
    path: SpotlightRoutes.NOT_FOUND,
    loadChildren: () =>
      import('../containers/sl-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('../containers/sl-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class SpotlightRoutingModule {}
