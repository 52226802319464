import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpotlightApiService } from '../sl-api/sl-api.service';

@Injectable({
  providedIn: 'root',
})
export class SpotlightEventTrackingService extends SpotlightApiService {
  trackEvent(directoryId: number, from: string, social: string): Observable<any> {
    const url = `/v1/recipient/analytics/spotlight_directories/${directoryId}/social_button_clicked`;

    return this._post(url, {
      from,
      social,
    }).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res, true)),
    );
  }
}
